import "@fortawesome/fontawesome-free/js/all.js";

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import ScrollReveal from "scrollreveal";

$(document).ready(function() {
    if (window.console) console.log('Loading clearbound js');

    ScrollReveal().reveal('section', { delay: 200 });    
    ScrollReveal().reveal('figure', { delay: 200 });
    ScrollReveal().reveal('img', { delay: 200 });
    ScrollReveal().reveal('.title', { delay: 100 });
    ScrollReveal().reveal('.subtitle', { delay: 200 });
    ScrollReveal().reveal('p', { delay: 100 });
    ScrollReveal().reveal('table', { delay: 200 }); 
    ScrollReveal().reveal('.column', { delay: 100 }); 

    if (window.console) console.log('scrollreveal loaded');
    
    $(".launchReflectionsModal").click(function() {
        $("#reflectionsModal").addClass("is-active"); 
    });

    $(".launchBrightnessModal").click(function() {
        $("#brightnessModal").addClass("is-active"); 
    });

    $(".launchHumidityModal").click(function() {
        $("#humidityModal").addClass("is-active"); 
    });

    $(".launchResistanceModal").click(function() {
        $("#resistanceModal").addClass("is-active"); 
    });	

    $(".launchModal").click(function() {

        $(".modal").addClass("is-active"); 
    });

    $(".modal-close").click(function() {
        $(".modal").removeClass("is-active");
    });

    $(".modal-background").click(function() {
        $(".modal").removeClass("is-active");
    });    

    $("#closebtn").click(function() {
        $(".modal").removeClass("is-active");
    });

    if (window.console) console.log('modals loaded');


    $(".navbar-burger").click(function() {
        $("#navbarBasic, .navbar-burger").toggleClass('is-active');
      });

    $("#navbarBasic a").click(function() {
    $   ("#navbarBasic, .navbar-burger").removeClass('is-active');
    });  

    if (window.console) console.log('nav loaded');


    $("#technicaldata-more").click(function() {
        $("#technicaldata-content").addClass("showAll");
        $("#technicaldata-content").fadeIn(200);
        $("#technicaldata-more").fadeOut(200);
    });

    if (window.console) console.log('clearbound js ready');


});